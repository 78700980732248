import React from "react";

import { MDBContainer, MDBRow, MDBCol, MDBFooter, MDBBtn } from "mdbreact";

import Logo from "../../assets/images/img-logo.svg";

export default function MainFooter() {
	return (
		<MDBFooter className="py-3 bg-green">
			<MDBContainer className="my-4 text-center text-md-left">
				<MDBRow>
					<MDBCol col="12" md="6">
						<h5>Conheça os portais da Universidade Feevale</h5>
						<ul className="pl-0 pl-md-5 list-unstyled">
							{[
								["https://www.feevale.br", "Universidade Feevale"],
								["https://way.feevale.br", "Graduação"],
								["https://pos.feevale.br", "Especializações e MBAs"],
								["https://www.feevale.br/stricto", "Mestrados e doutorados"],
								["https://escola.feevale.br", "Escola de Aplicação"],
								["https://www.feevale.br/idiomas", "Idiomas"],
								["https://feevaletechpark.com.br", "Feevale Techpark"],
								["https://teatrofeevale.com.br", "Teatro Feevale"]
							].map((i, index) => {
								return (
									<li key={"link" + index}>
										<a href={i[0]} target="_blank" rel="noreferrer">{i[1]}</a>
									</li>
								);
							})}
						</ul>
					</MDBCol>
					<MDBCol col="12" md="6">
						<MDBContainer fluid>
							<MDBRow>
								<MDBCol>
									<h5>Fale conosco</h5>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol>
									<MDBBtn color="white" outline className="w-100" href="tel:5135868800">(51) 3586-8800</MDBBtn>
								</MDBCol>
								<MDBCol>
									<MDBBtn color="white" outline className="w-100" href="mailto:falecomafeevale@feevale.br">falecomafeevale@feevale.br</MDBBtn>
								</MDBCol>
							</MDBRow>
							<MDBRow>
								<MDBCol>
									<MDBBtn color="white" outline className="w-100" href="https://www.feevale.br/falecomafeevale">Fale com a Feevale</MDBBtn>
								</MDBCol>
							</MDBRow>
						</MDBContainer>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol className="text-center">
						<a href="https://www.feevale.br" target="_blank" rel="noreferrer">
							<img
								src="https://www.feevale.br/s/conteudo/b3af5fc4-f40d-42cd-bedb-86291657abf3/Feevale-selo55anos-bco.svg"
								className="img-fluid"
								style={{ width: 250 }}
								alt="Universidade Feevale"
								title="Universidade Feevale"
							/>
						</a>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</MDBFooter>
	);
}
